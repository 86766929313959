import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import Box from '@mui/material/Box';
import './getQuote.css';
import coffee from '../../assets/Coffee.png';
import img3 from '../../assets/greysclose.svg';

const GetQuote = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: "auto",
        bgcolor: 'background.paper',
        borderRadius: '20px',
        boxShadow: 24,
        display: 'flex',
        width: "auto",
        outline: 'none',
        padding: "2em 1em 2em 2em",

    };
    const [open, setOpen] = useState(false);
    const [fullName, setFullName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [industry, setIndustry] = useState('');
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const handleClose = () => {
        setOpen(false);
    }

    const getAQuote = (e) => {
        e.preventDefault()
        if (fullName.length === 0 || companyName.length === 0 || email.length === 0 || industry.length === 0 || text.length === 0) {
            alert("All fields are required");
            return;
        }


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert("Email format is invalid")
            return;
        }
        const body = {
            full_name: fullName,
            company_name: companyName,
            email: email,
            industry: industry,
            project_brief: text
        };


        try {
            setLoading(true)
            const config = {
                headers: {
                    "Content-Type": "application/json",

                },
            }; axios.post(`${baseUrl}/forms/quote-request`, body, config)
                .then((res) => {
                    console.log(res)
                    setLoading(false)
                    setShowSuccess(true)


                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error);
                    alert(error.response.data.message);


                });
        } catch (error) {
            console.log("error", error);
        }


    }
    return (
        <>


            <div className="service-hero-btn">
                <button onClick={() => setOpen(true)}>
                    Get a Quote
            </button>
            </div>

            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>


                    {showSuccess ?
                        <div className="quote-container">
                            <div className="quote-success">
                                <h4>Get a quote</h4>

                                <p>Our team will review your brief and give you a quote in 3-5 business days.</p>
                                <div>
                                    <img src={coffee} alt="icon" />
                                </div>


                                <button style={{ width: '280px' }} onClick={() => setOpen(false)}>Done</button>
                            </div>

                        </div>


                        :
                        <form onSubmit={getAQuote}>
                        <div className="quote-container">


                            <h4>Get a quote</h4>


                            <p>Our team will review your brief and give you a quote in 3-5 business days.</p>


                            <div className="quote-input-container">
                                <div>
                                    <label>Your name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter full name e.g john doe"
                                        required
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>Company name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter"
                                        required
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        placeholder="Your email"
                                        value={email}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <label>Industry</label>
                                    <input
                                        type="text"
                                        placeholder="Your industry"
                                        value={industry}
                                        required
                                        onChange={(e) => setIndustry(e.target.value)}
                                    />
                                </div>


                            </div>

                            <div className="non-input-quote">
                                <label>Project Brief</label>
                                <textarea
                                    value={text}
                                    required
                                    placeholder='Enter text here...'
                                    onChange={(e) => setText(e.target.value)}
                                >

                                </textarea>
                                <small>Keep this below 750 words</small>
                            </div>

                            <button type='submit' disabled={loading}>
                                {loading ? 'Submitting...' : 'Submit Quote Request'}
                            </button>

                            {showSuccess && <p>Your quote request has been submitted successfully!</p>}



                            <div >
                                <img
                                    src={img3}
                                    alt="close"
                                    className="closeIcon-quote"
                                    onClick={() => handleClose()}
                                />
                            </div>
                        </div>
                        </form>
                    }





                </Box>
            </Modal>
        </>
    )
}

export default GetQuote
