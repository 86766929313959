import React, { useEffect } from 'react';
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import AboutPage from './components/AboutPage/AboutPage';
import Services from './components/Services/Services';
// import FAQandPricing from './components/FAQandPricing/FAQandPricing';
import Contact from './components/Contact/Contact';
import TermsOfService from './components/TermsOfService/TermsOfService';
import Privacy from './components/Privacy/Privacy';
// import Consumer from './components/Consumer/Consumer';
// import Contributor from './components/Contributor/Contributor';

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
       (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_PROJECT_ID}");
      `;
      document.head.appendChild(script);
    }
  }, []);

  return (

    <Router>

      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path ="/termsofservice" element={<TermsOfService/>} />
          <Route path ="/privacypolicy" element={<Privacy/>} />
          {/* <Route path="/faqandpricing" element={<FAQandPricing />} /> */}
          {/* <Route path="/consumer" element={<Consumer />} />
          <Route path="/contributor" element={<Contributor />} /> */}

        </Routes>
      </div>
    </Router>
  )
}

export default App
