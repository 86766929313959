import React, { useState } from 'react';
import './earlyAccess.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import img1 from '../../assets/waitList.svg'
import img2 from '../../assets/modalimage.png'
import img3 from '../../assets/greysclose.svg'
import img4 from '../../assets/deal.svg';
import axios from 'axios'
const EarlyAccessBtn = ({ height, padding }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSucess, setShowSucess] = useState(false);
    const [email, setEmail] = useState("")
    const [fullName, setFullName] = useState("")
    const buttonStyle = {
        height: height,
        padding: padding,

    };

    const handleClose = () => {
        setOpen(false);
    }

    const afterWaitlist = () => {
        setOpen(false);
        setShowSucess(false)
    }

    const joinWaitlist = (e) => {
        e.preventDefault()

        if (email.length === 0 || fullName.length === 0) {
            alert("all fields are required")
            return;
        }


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert("Email format is invalid")
            return;
        }
        var body =
        {
            "full_name": fullName,
            "email": email
        }


        try {
            setLoading(true)
            const config = {
                headers: {
                    "Content-Type": "application/json",

                },
            }; axios.post(`${baseUrl}/waitlist`, body, config)
                .then((res) => {

                    setLoading(false)
                    setShowSucess(true)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error);
                    alert(error.response.data.message);


                });
        } catch (error) {
            console.log("error", error);
        }


    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 502,
        bgcolor: 'background.paper',
        borderRadius: '20px',
        boxShadow: 24,
        display: 'flex',
        width: "auto",
        outline: 'none',
    };

    return (
        <>
            <div>
                <button
                    onClick={() => setOpen(true)}
                    className="access-btn" style={buttonStyle}>
                    Get Early Access
                </button>
            </div>

            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>


                    {showSucess ?
                        <div className="modal-content">

                            <div>
                                <h4>Thank you! </h4>

                            </div>

                            <p>You have joined other passionate individuals and business who are eager to use Sonaroid’s insights.</p>

                            <div className="left-img" >
                                <img
                                    src={img4}
                                    alt="group"
                                />
                            </div>
                            <button onClick={() => afterWaitlist()}>Done</button>
                        </div>


                        :
                        <form onSubmit={joinWaitlist}>
                        <div className="modal-content">

                            <div>
                                <h4>Join us and get early
                                access to Sonaroid</h4>
                            </div>

                            <p>Be one of the first to get notified when we launch.</p>

                            {/* <div className="left-img" >
                                <img
                                    src={img1}
                                    alt="group"
                                />
                            </div> */}

                            <input
                                type="text"
                                required
                                placeholder="Your Full Name e.g John doe"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Your email"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {loading ?
                                <p>please wait..</p>
                                : <button type='submit'>Join waitlist</button>}

                            <div>
                                <p>By signing up, you agree to our <span> <a href='/termsofservice'>Terms and Conditions</a></span> and
                                 <span> <a href='/privacypolicy'>Privacy Policy</a></span></p>
                            </div>

                            <img
                                src={img3}
                                alt="close"
                                className="closeIcon_two"
                                onClick={() => handleClose()}
                            />


                        </div>
                        </form>
                    }

                    <div className="right-content">
                        <img
                            src={img2}
                            alt="modalimg"
                            className="right-content-img"
                        />

                        <img
                            src={img3}
                            alt="close"
                            className="closeIcon"
                            onClick={() => handleClose()}
                        />
                    </div>


                </Box>
            </Modal>
        </>
    );
};

export default EarlyAccessBtn;
